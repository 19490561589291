<template>
  <div class="layout-group py-4">
    <div class="text-color-2 text-center h3 display-6 fw-normal m-0">
      Pending Staff Authorization
    </div>
     <div class="text-color-2 text-center fs-6 fw-normal m-0">
      Your account is not yet staff authorized. All user accounts need to be authorized by a system administrator before the system can be accessed You will receive an email from the administrators as soon as it has been authorized by them. From there you will be able to login in and gain access to the users application.
    </div>
    
  </div>
  
</template>


