<template>
<div>
    <div>FORCE AN ERROR</div>
</div>    
</template>

<script setup>
import { useErrorHandler } from "@/stores/ErrorHandler.js";


function createError() {
  try {
    let a = null;
    let myError = a[3]
  } catch (error) {
    useErrorHandler().processScriptError(error)
  }
}

createError()

</script>