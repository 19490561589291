<template>
  <div :class="getClassDynamic" class="bg">
    <div class="bg inner" style="overflow-y: auto;max-height:400px">
        <div
      v-for="(debug, index) in listData"
      :key="'debug_' + index"
      class="d-flex flex-wrap align-items-center pa-1"
      style="border-bottom:1px solid #ccc"
    >
      <div class="d-flex flex-wrap align-items-center">
        <div class="text-align-left fw-bold fs-6" style="word-break: break-all;color:#fff;width: 200px">
          {{ debug.name }}
        </div>
      </div>

      <div
        class="w-100 d-grid"
        style="flex: 1 1 0; grid-template-columns: repeat(auto-fit, minmax(90px, 1fr)); gap: 0.2rem"
      >
        <div v-for="(debugProp, index2) in debug.props" :key="'debug_prop_' + index + '_' + index2" class="pa-1"  style="background-color: #ffffff16;">
          <div class="mb-0 fs-9 text-align-left"  style="color:#ccc;">{{ debugProp.label }}</div>
          <div class="mb-0 fs-7 fw-bold text-align-left" style="color:#fff;">{{ debugProp.value }}</div>
        </div>
      </div>
    </div>

    </div>
  
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useApplicationStore } from '@/stores/ApplicationStore.js'
const applicationStore = useApplicationStore()
const props = defineProps({
  listData: {
    type: Object,
    default: () => {
      return {}
    }
  }
 
})

const getClassDynamic = computed(() => {
  let ob = {
    'pa-2': applicationStore.grid.breakpoint === 'xs',
    'pa-4': applicationStore.grid.breakpoint !== 'xs',
    'rounded-8': !applicationStore.grid.tabletAndUnder,
    'elevation-6': !applicationStore.grid.tabletAndUnder
  }
  
  return ob
})

</script>

<style scoped>

.bg{
    background-color: #165489
}


.bg ::-webkit-scrollbar-thumb {
    background-color: #303d61;
}

.bg ::-webkit-scrollbar-track {
    background-color: #447caa;
}

</style>
