import { defineStore } from 'pinia'
export const useGlobalInjects = defineStore('GlobalInjects', {
  state: () => {
    return {
    
      route:null,
      router:null,
     
    }
  }, 

 
})
