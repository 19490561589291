import { defineStore } from "pinia";
import { computed } from "vue";
export const useTheme = defineStore("Theme", {
  state: () => {
    return {
     
        themeKey:null,
        renderIndex:null
   
    };
  },
 
  actions: {

    readTheme(){
      this.themeKey = localStorage.getItem("themekey")||'light';
      return this.themeKey;
    },
    writeTheme(payload){
      this.themeKey = payload;
      localStorage.setItem("themekey", payload);
    },

    readRenderIndex(){
      this.renderIndex = parseInt(localStorage.getItem("renderindex")||0);
      return this.renderIndex;
    },
    writeRenderIndex(payload){
      this.renderIndex = payload;
      localStorage.setItem("renderindex", payload);
    },

  }
 
 
});
